import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

export default function CustomSelect({
  options,
  placeholder,
  formatCreateLabel,
  value,
  onChange,
  isClearable = true,
  isOpacity = 1,
  variant = "invoice-table-restyle",
  className,
  classNamePrefix,
  fontColor = "var(--b-color-text-primary-1)",
  toTranslate = true
}) {
  const { t, i18n } = useTranslation();
  const [translatedValue, setTranslatedValue] = useState(null);

  const translatedOptions = options.map(option => ({
    label: t(option.label),
    value: option.value,
  }));

  useEffect(() => {
    if (toTranslate) {
      if (value) {
        setTranslatedValue({
          value: value.value,
          label: t(value.value),
        });
      } else {
        setTranslatedValue(null);
      }
    }
  }, [value, i18n.language]);

  const styleVariants = {
    "invoice-table-restyle": {
      container: (baseStyles) => ({
        ...baseStyles,
        width: isMobileOnly ? "100%" : "100%",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "red",
        width: "100%",
        height: isMobileOnly ? "30px" : "40px",
        padding: isMobileOnly ? "0px 15px" : "0 30px",
        borderRadius: "13px",
        border: "1px solid transparent",
        background: "var(--b-color-bg-main-2)",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontSize: isMobileOnly ? "16px" : "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        minHeight: "auto",
        opacity: isOpacity,
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "21px",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: "var(--b-color-text-primary-1)",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: "200px",
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "18px",
        color: 'var(--b-color-text-primary-1)',
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        fontVariantNumeric: "lining-nums proportional-nums",
        background: state.isSelected ? 'var(--b-color-bg-main-3)' : (state.isFocused ? 'var(--b-color-bg-main-3)' : ''),
        borderRadius: '13px',
        padding: '8px 30px',
        cursor: 'pointer',
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
            width: '90%',
            height: '2px',
            background: 'var(--b-color-bg-additional-2)',
          }
        }
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
        fontSize: isMobileOnly ? "16px" : "21px",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        fontVariantNumeric: "lining-nums proportional-nums",
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
      }),
      indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: isMobileOnly ? "30px" : "40px",
      }),
    },
    "modal-editable-invoice": {
      container: (baseStyles) => ({
        ...baseStyles,
        width: isMobileOnly ? "100%" : "100%",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "red",
        width: "100%",
        height: isMobileOnly ? "30px" : "40px",
        padding: isMobileOnly ? "0px 5px" : "0 30px",
        borderRadius: "13px",
        border: "1px solid transparent",
        background: "var(--b-color-bg-main-2)",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontSize: isMobileOnly ? "16px" : "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        minHeight: "auto",
        opacity: isOpacity,
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "21px",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: "var(--b-color-text-primary-1)",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: "100px",
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "18px",
        color: 'var(--b-color-text-primary-1)',
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        fontVariantNumeric: "lining-nums proportional-nums",
        background: state.isSelected ? 'var(--b-color-bg-main-3)' : (state.isFocused ? 'var(--b-color-bg-main-3)' : ''),
        borderRadius: '13px',
        padding: '8px 30px',
        cursor: 'pointer',
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
            width: '90%',
            height: '2px',
            background: 'var(--b-color-bg-additional-2)',
          }
        }
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
        fontSize: isMobileOnly ? "16px" : "21px",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        fontVariantNumeric: "lining-nums proportional-nums",
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
      }),
      indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: isMobileOnly ? "30px" : "40px",
      }),
    },
    "modal-form-phone": {
      container: (baseStyles) => ({
        ...baseStyles,
        width: isMobileOnly ? '100%' : '50%',
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'grey' : 'red',
        width: '100%',
        height: isMobileOnly ? '30px' : '40px',
        padding: '0px 35px',
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontSize: isMobileOnly ? '16px' : '21px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        minHeight: 'auto',
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: 'none',
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? '16px' : '21px',
        color: 'var(--b-color-bg-additional-2)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: 'var(--b-color-text-primary-1)',
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: '200px',
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? '16px' : '18px',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        fontVariantNumeric: 'lining-nums proportional-nums',
        background: state.isSelected ? 'var(--b-color-bg-main-3)' : (state.isFocused ? 'var(--b-color-bg-main-3)' : ''),
        borderRadius: '13px',
        padding: '8px 30px',
        cursor: 'pointer',
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
            width: '90%',
            height: '2px',
            background: 'var(--b-color-bg-additional-2)',
          }
        }
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: '0px',
        fontSize: isMobileOnly ? '16px' : '21px',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        fontVariantNumeric: 'lining-nums proportional-nums',
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: '0px',
      }),
      indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: isMobileOnly ? '30px' : '40px',
      }),
    },
    "claims-filter": {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "red",
        border: "2px solid var(--b-color-main-1)",
        borderRadius: "12px",
        background: "var(--b-color-bg-main-2)",
        height: "47px",
        maxWidth: "280px",
        fontSize: "20px",
        fontWeight: "700",
        boxShadow: "none",
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: "22px",
        color: "var(--b-color-main-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: "var(--b-color-main-1)",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: "200px",
        zIndex: 100,
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isFocused && "var(--b-color-main-1)",
        fontSize: "14px",
        color: state.isFocused ? "var(--b-color-text-primary-2)" : "var(--b-color-main-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        fontVariantNumeric: "lining-nums proportional-nums",
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        fontSize: "18px",
        color: "var(--b-color-main-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        fontVariantNumeric: "lining-nums proportional-nums",
      }),
    },
    "modal-edit-data": {
      container: (baseStyles) => ({
        ...baseStyles,
        width: isMobileOnly ? "100%" : "540px",
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "red",
        width: "100%",
        height: isMobileOnly ? "30px" : "40px",
        padding: isMobileOnly ? "0px 15px" : "0 30px",
        borderRadius: "13px",
        border: "1px solid var(--b-color-bg-additional-5)",
        background: "var(--b-color-bg-main-2)",
        boxShadow: "0px 0px 8px 0px var(--b-shadow-main)",
        color: "var(--b-color-text-primary-1)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontSize: isMobileOnly ? "16px" : "21px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "normal",
        minHeight: "auto",
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: "none",
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "21px",
        color: "var(--b-color-bg-additional-2)",
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: "var(--b-color-text-primary-1)",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: "200px",
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? "16px" : "18px",
        color: 'var(--b-color-text-primary-1)',
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "24px",
        fontVariantNumeric: "lining-nums proportional-nums",
        background: state.isSelected ? 'var(--b-color-bg-main-3)' : (state.isFocused ? 'var(--b-color-bg-main-3)' : ''),
        borderRadius: '13px',
        padding: '8px 30px',
        cursor: 'pointer',
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
            width: '90%',
            height: '2px',
            background: 'var(--b-color-bg-additional-2)',
          }
        }
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
        fontSize: isMobileOnly ? "16px" : "21px",
        color: fontColor,
        fontFamily: "var(--b-font-family-primary-1)",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "normal",
        fontVariantNumeric: "lining-nums proportional-nums",
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: "0px",
      }),
      indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: isMobileOnly ? "30px" : "40px",
      }),
    },
    "modal-cancel-contract": {
      container: (baseStyles) => ({
        ...baseStyles,
        width: isMobileOnly ? '100%' : '540px',
      }),
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'grey' : 'red',
        cursor: 'pointer',
        width: '100%',
        height: isMobileOnly ? '30px' : '40px',
        padding: isMobileOnly ? "0px 15px" : "0 30px",
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontSize: isMobileOnly ? '16px' : '21px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: 'normal',
        minHeight: 'auto',
      }),
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        display: 'none',
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? '16px' : '21px',
        color: 'var(--b-color-bg-additional-2)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
      }),
      dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: 'var(--b-color-text-primary-1)',
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        minWidth: '200px',
        borderRadius: '13px',
        border: '1px solid var(--b-color-bg-additional-5)',
        background: 'var(--b-color-bg-main-2)',
        boxShadow: '0px 0px 8px 0px var(--b-shadow-main)',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        fontSize: isMobileOnly ? '16px' : '18px',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px',
        fontVariantNumeric: 'lining-nums proportional-nums',
        background: state.isSelected ? 'var(--b-color-bg-main-3)' : (state.isFocused ? 'var(--b-color-bg-main-3)' : ''),
        borderRadius: '13px',
        padding: '8px 30px',
        cursor: 'pointer',
        position: 'relative',
        '&:not(:last-child)': {
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: '0',
            width: '90%',
            height: '2px',
            background: 'var(--b-color-bg-additional-2)',
          }
        }
      }),
      singleValue: (baseStyles) => ({
        ...baseStyles,
        padding: '0px',
        fontSize: isMobileOnly ? '16px' : '21px',
        color: 'var(--b-color-text-primary-1)',
        fontFamily: 'var(--b-font-family-primary-1)',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: 'normal',
        fontVariantNumeric: 'lining-nums proportional-nums',
      }),
      valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: '0px',
      }),
      indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        height: isMobileOnly ? '30px' : '40px',
      }),
    },
  }

  return (
    <Select
      options={toTranslate ? translatedOptions : options}
      placeholder={placeholder}
      formatCreateLabel={formatCreateLabel}
      value={toTranslate ? translatedValue : value}
      onChange={onChange}
      isClearable={isClearable}
      className={className}
      classNamePrefix={classNamePrefix}
      styles={styleVariants[variant]}
    />
  )
}
